import React, { useRef } from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Tabs from "../../components/tabs/Tabs";
import TabLink from "../../components/tabs/TabLink";
import TabContent from "../../components/tabs/TabContent";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import useWindowSize from "../../hooks/useWindowSize";

import Layout from "../../components/mschool/layout";
import SchedulePopup from "../../components/schedulepopup";
import SEO from "../../components/seo";
import TopBanner from "../../components/mschool/topbanner";
import Power from "../../components/mschool/power";
import ProInstructors from "../../components/mschool/proinstructors";
import settings from "../../../settings";
import Track from "../../components/track";

// banner
const banner =
	`${settings.IMAGES_BASE_URL}/images/one-day-m-school/one-day-m-banner.jpg`;
const bannerMobile =
	`${settings.IMAGES_BASE_URL}/images/one-day-m-school/one-day-m-banner.jpg`;
// schools
const twodaymschool =
	`${settings.IMAGES_BASE_URL}/images/classes/two-day-m-class.jpg`;
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/images/classes/advanced-m-school-banner%201.png`;
const sccaschool =
	`${settings.IMAGES_BASE_URL}/images/classes/race-license-class.jpg`;
const mdriver =
	`${settings.IMAGES_BASE_URL}/images/classes/m-drivers-class.jpg`;
const m4gt4school =
	`${settings.IMAGES_BASE_URL}/images/classes/m4-gt4-class.jpg`;
const indyfullday =
	`${settings.IMAGES_BASE_URL}/images/indy_images/full-day-indy-box.jpg`;

// learn
const drifting =
	`${settings.IMAGES_BASE_URL}/images/two-day-m-school/drifting.jpg`;
const learnline =
	`${settings.IMAGES_BASE_URL}/images/one-day-m-school/learn_the_line.jpg`;
const highspeed =
	`${settings.IMAGES_BASE_URL}/images/two-day-m-school/high-speed-track.jpg`;
const ratrace =
	`${settings.IMAGES_BASE_URL}/images/two-day-m-school/rat-race.jpg`;
const timeattack =
	`${settings.IMAGES_BASE_URL}/images/two-day-m-school/time-atack.jpg`;

const Agenda = () => {
  return (
    <SchedulePopup
      btnText="View One-Day M School Agenda"
    >

      <div className="two_days_schedule">
        <div className="mr_10">
          <div>
            <h6>ONE-DAY M SCHOOL</h6>
            <small>Sample schedule subject to change</small>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:00 am-8:30 am</p>
            <p className="schedule__item__event">
              Arrival, registration
            </p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">8:30 am-9:30 am</p>
            <p className="schedule__item__event">
              Class discussion
            </p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">9:30 am-10:00 am</p>
            <p className="schedule__item__event">Proper seating / Warm-up slalom</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">10:00 am-12:00 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">12:00 pm-1:00 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">1:00 pm-4:00 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">4:00 pm-4:15 pm</p>
            <p className="schedule__item__event">Closing</p>
          </div>
        </div>
        <div>
          <div>
            <h6>ONE-DAY M SCHOOL - <br /> THERMAL SUMMER <br /> SCHEDULE</h6>
            <small>Summer hours for our California location only, <br /> valid
              from June 1- September 30.
            </small>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:00 am-6:30 am</p>
            <p className="schedule__item__event">Arrival, registration</p>
          </div>
          <div className="schedule__item">
            <p className="schedule__item__time">6:30 am-7:30 am</p>
            <p className="schedule__item__event">Class discussion</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">7:30 am-12:30 pm</p>
            <p className="schedule__item__event">Drive time</p>
          </div>

          <div className="schedule__item">
            <p className="schedule__item__time">12:30 pm-1:30 pm</p>
            <p className="schedule__item__event">Lunch</p>
          </div>
        </div>
      </div>
    </SchedulePopup>
  );
};

const OneDayMSchoolPage = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};
	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	const learnRef = useRef(null);
	const loactionsRef = useRef(null);
	const powerRef = useRef(null);
	const instructorsRef = useRef(null);
	const classesRef = useRef(null);
	return (
		<Layout>
			<SEO
				title="BMW Performance Center | One-Day M School"
				description="M School is all about limits, and you'll discover just how high we set them with adrenaline-pumping exercises designed to bring out your inner M."
				keywords="bmw m school, m driving school, bmw m driving school, bmw m performance driving school"
			/>
			<div className="onedaymschoolpage">
				<TopBanner
					image={banner}
					mobileImage={bannerMobile}
					header="ONE-DAY M SCHOOL"
					subheader="UNLEASH M POWER"
					pricing="FROM $1,750"
					text="The modern BMW M car is so capable, you’ll be astonished by what it can do on track. Our pro instructors guide you over the course of a full day, and by the time it’s all over, you’ll see just what M (and you) can do."
					buttonText="Book in California"
					butttonURL="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=One_Day_M_School"
					buttonTwoText="Book in South Carolina"
          showbuttonTwoTextInMob="true"
					butttonTwoURL="https://pds.eventsbmw.com/?location=Spartanburg%2C%20SC&brand=M&class=One_Day_M_School"
          showbuttonTwoTextInMob="true"
					agendaPopup={<Agenda />}
				>
          <p>
            Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details.
          </p>
        </TopBanner>
				<section className="links__tabs">
					<div>
						<ul>
							<li>
								<button
									onClick={() => {
										scrollToView(learnRef);
									}}
								>
									What you’ll learn
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(loactionsRef);
									}}
								>
									Locations
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(powerRef);
									}}
								>
									What you’ll drive
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(instructorsRef);
									}}
								>
									Pro instructors
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(classesRef);
									}}
								>
									Other classes
								</button>
							</li>
						</ul>
					</div>
				</section>
				<section className="learn" ref={learnRef}>
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Drifting">
								<LazyLoadImage effect="blur" src={drifting} alt="Drifting" />
								<div className="accordion-content">
									<h3>Slip, then grip</h3>
									<p>
										Take one M car. Add water, subtract grip. Feel the
										difference between under- and oversteer, and learn to
										control the back of the car with throttle. Get it right, and
										you’ll start to glide through our polished concrete skidpad
										with ease.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Learn the Line">
								<LazyLoadImage
									effect="blur"
									src={learnline}
									alt="Learn the Line"
								/>
								<div className="accordion-content">
									<h3>Technical Perfection</h3>
									<p>
										These tracks are set up to be short, with aggressive turns
										that require a balance between throttle and brake. You’ll
										learn this dance by looking as far ahead as possible and
										planning the proper drive lines through compromise corners.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="High Speed Track">
								<LazyLoadImage
									effect="blur"
									src={highspeed}
									alt="High Speed Track"
								/>
								<div className="accordion-content">
									<h3>Floor it</h3>
									<p>
										Your ability to maintain focus will be tested. Cycle through
										multiple M cars and experience high speeds for sustained
										periods.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Rat Race">
								<LazyLoadImage effect="blur" src={ratrace} alt="Rat Race" />
								<div className="accordion-content">
									<h3>Claw for first</h3>
									<p>
										Wet tarmac. An oval track. And you, lined up on the opposite
										side of your foe. Step on it and see if you can catch up to
										them. But be alert - no traction control allowed here.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Time Attack">
								<LazyLoadImage
									effect="blur"
									src={timeattack}
									alt="Time Attack"
								/>
								<div className="accordion-content">
									<h3>Race the Clock</h3>
									<p>
										Once you’ve mastered the autocross section, you’ll head back
										in the afternoon for some timed runs against the other
										drivers. Smoothness is the key here. Win the day and you’ll
										be Master of M.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__images">
									<TabContent for="drifting" uniqueKey="1">
                      <img src={drifting} alt="Drifting" />
                    </TabContent>
                    <TabContent for="autocross" uniqueKey="2">
                      <img src={learnline} alt="Learn the Line" />
                    </TabContent>
                    <TabContent for="high-speed" uniqueKey="3">
                      <img src={highspeed} alt="High Speed Track" />
                    </TabContent>
                    <TabContent for="rat-race" uniqueKey="4">
                      <img src={ratrace} alt="Rat Race" />
                    </TabContent>
                    <TabContent for="time-attack" uniqueKey="5">
                      <img src={timeattack} alt="Time Attack" />
                    </TabContent>
								</div>
								<ul className="learn__menu" role="tablist" aria-owns="drifting autocross high-speed time-attack rat-race" aria-busy="true">
									<li role="presentation">
										<TabLink role="tab" id="drifting"  to="drifting" uniqueKey="6">Drifting</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="autocross" to="autocross" uniqueKey="7">Learn the Line</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="high-speed" to="high-speed" uniqueKey="8">High-Speed Track</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="rat-race" to="rat-race" uniqueKey="9">Rat Race</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="time-attack" to="time-attack" uniqueKey="10">Time Attack</TabLink>
									</li>
								</ul>
								<div className="learn__content">
									<TabContent for="drifting" uniqueKey="11">
										<h3>Slip, then grip</h3>
										<p>
											Take one M car. Add water, subtract grip. Feel the
											difference between under- and oversteer, and learn to
											control the back of the car with throttle. Get it right,
											and you’ll start to glide through our polished concrete
											skidpad with ease.
										</p>
									</TabContent>
									<TabContent for="autocross" uniqueKey="12">
										<h3>Technical Perfection</h3>
										<p>
											These tracks are set up to be short, with aggressive turns
											that require a balance between throttle and brake. You’ll
											learn this dance by looking as far ahead as possible and
											planning the proper drive lines through compromise
											corners.
										</p>
									</TabContent>
									<TabContent for="high-speed" uniqueKey="13">
										<h3>Floor it</h3>
										<p>
											Your ability to maintain focus will be tested. Cycle
											through multiple M cars and experience high speeds for
											sustained periods.
										</p>
									</TabContent>
									<TabContent for="rat-race" uniqueKey="14">
										<h3>Claw for first</h3>
										<p>
											Wet tarmac. An oval track. And you, lined up on the
											opposite side of your foe. Step on it and see if you can
											catch up to them. But be alert - no traction control
											allowed here.
										</p>
									</TabContent>
									<TabContent for="time-attack" uniqueKey="15">
										<h3>Race the Clock</h3>
										<p>
											Once you’ve mastered the autocross section, you’ll head
											back in the afternoon for some timed runs against the
											other drivers. Smoothness is the key here. Win the day and
											you’ll be Master of M.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>

					<Track variant="black" ref={loactionsRef}/>
				<Power ref={powerRef} />

				<ProInstructors ref={instructorsRef} />
				<section className="other-schools" ref={classesRef}>
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other-schools__content row">
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={twodaymschool}
								alt="Two-Day M School"
							/>
							<h3>Two-Day M School</h3>
							<p>Focus on the details of true M Performance</p>
							<Link to="/mschool/twodaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={sccaschool}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>Become a pro.</p>
							<Link to="/mschool/racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={mdriver}
								alt=">M Driver’s Program"
							/>
							<h3>M Driver’s Program</h3>
							<p>Take yourself to the limit.</p>
							<Link to="/mschool/mdriver">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={m4gt4school}
								alt="M4 GT4 School"
							/>
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={indyfullday}
								alt="Full-Day M Experience at Indy"
							/>
							<h3>Full-Day M Experience at Indy</h3>
							<p>Full-on thrills on a hallowed track.</p>
							<Link to="/indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default OneDayMSchoolPage;
